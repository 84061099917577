const baseUrl = 'https://z-labo.co.jp/wp-json/wp/v2/'

export function scCompornent(){
  const temp = {
    data: function(){
      return {
        demo: 'hello world!',
        items: {},
        list: [],
        success: true,
        loaded: false,
      }
    },
    methods:{
      getYear: function(){
        const now = new Date()
        return now.getFullYear()
      },
      initalize: function(){
        fetch(`${baseUrl}schedule/${this.items[this.getYear()]}`)
          .then(response => response.json())
          .then(data => {
            this.list = data
            delete this.list.acf.select
            setTimeout(()=>{
              if (data.data && data.data.status === 404) {
                this.success = false
              }
              this.loaded = true
            }, 1000)
          })
      },
      changeList: function(str){
        const dataset = this.items[str]
        if(dataset){
          fetch(`${baseUrl}schedule/${dataset}`)
          .then(response => response.json())
          .then(data => {
            this.list = data
            delete this.list.acf.select
          })
          .catch(error => {
            console.error('Error fetching list data:', error);
            this.success = false
          })
        }
        else{
          alert('error dataset is null')
        }
      },
      roomName: function(str){
        const numbers = str.match(/\d+/g);
        return numbers.join(''); 
      },
      close: function(){
        const el = document.getElementById('availability')
        if(el){
          if(el.classList.contains('active')){
            el.classList.add('fadeout')
            setTimeout(()=>{
              el.classList.remove('fadeout')
              el.classList.remove('active')
            },800)
          }else{
            el.classList.add('active')
          }
        }
      }
    },
    mounted() {
      const currentYear = this.getYear()
      fetch(`${baseUrl}schedule/`)
        .then(response => response.json())
        .then(data => {
          data.forEach(el => {
            if(el.title.rendered == currentYear || el.title.rendered == currentYear + 1){
              this.items[el.title.rendered] = el.id
            }
          })
        })
        .then(
          ()=> { this.initalize() }
        )
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    },
    template: 
    `
    <div class="availability" id="availability">
      <div class="availability__box" v-if="loaded && success">
        <div class="availability__box__close" @click="close()"></div>
        <div class="availability__box__inner">
          <h4>試験場の空き状況</h4>
          <figure><img src="/images/common/fig.webp" alt="試験場"></figure>
          <div class="availability__box__inner__meta" v-if="list.title != null">
            <h5>{{ list.title.rendered }}年</h5>

            <template v-if="list.title.rendered == getYear() && (getYear() + 1) in items">
              <p class="next" @click="changeList(getYear() + 1)">来年へ</p>
            </template>

            <template v-if="list.title.rendered != getYear()">
              <p class="prev" @click="changeList(getYear())">前年へ</p>
            </template>

          </div>
          <div class="availability__box__inner__tablebox">
            <table>
              <thead>
                <tr>
                  <th colspan="2">試験場</th>
                  <td>1月</td>
                  <td>2月</td>
                  <td>3月</td>
                  <td>4月</td>
                  <td>5月</td>
                  <td>6月</td>
                  <td>7月</td>
                  <td>8月</td>
                  <td>9月</td>
                  <td>10月</td>
                  <td>11月</td>
                  <td>12月</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, key) in list.acf" :key="key" :class="{'part': roomName(key) == 7 }">
                  <th class="head" rowspan="6" v-if="roomName(key) == 1">1</th>
                  <th class="head" rowspan="6" v-if="roomName(key) == 7">2</th>
                  <th><span class="cir">{{ roomName(key) }}</span></th>
                  <template v-for="(child, childIndex) in item">
                    <td v-if="child == 0"><i class="fa-solid fa-xmark"></i></td>
                    <td v-if="child == 1" class="alt">要確認</td>
                    <td v-if="child == 2" class="active">空き</td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="availability__box" v-else>
        <div class="availability__box__close openWindow"></div>
        <div class="availability__box__inner">
          <p class="availability__box__inner__alert" v-if="!loaded"><img src="/images/common/loading.gif">データを読み込んでいます</p>
          <p class="availability__box__inner__alert" v-else>登録されているデータが存在しません</p>
        </div>
      </div>
    </div>
    `
  }
  return temp;
};
